@font-face {
  font-family: "Apercu Pro";
  src: url("./Apercu-Regular-Pro.eot");
  src: url("./Apercu-Regular-Pro.eot?#iefix") format("embedded-opentype"),
    url("./Apercu-Regular-Pro.woff") format("woff"),
    url("./Apercu-Regular-Pro.woff2") format("woff2"),
    url("./Apercu-Regular-Pro.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Apercu Pro";
  src: url("./Apercu-Bold-Pro.eot");
  src: url("./Apercu-Bold-Pro.eot?#iefix") format("embedded-opentype"),
    url("./Apercu-Bold-Pro.woff") format("woff"),
    url("./Apercu-Bold-Pro.woff2") format("woff2"),
    url("./Apercu-Bold-Pro.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: "Apercu Pro";
  src: url("./Apercu-Light-Pro.eot");
  src: url("./Apercu-Light-Pro.eot?#iefix") format("embedded-opentype"),
    url("./Apercu-Light-Pro.woff") format("woff"),
    url("./Apercu-Light-Pro.woff2") format("woff2"),
    url("./Apercu-Light-Pro.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "ATT Aleck Sans";
  src: url("./ATT/ATTAleckSans/att_aleck_sans_md.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "ATT Aleck Sans";
  src: url("./ATT/ATTAleckSans/att_aleck_sans_lt.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "ATT Aleck Sans";
  src: url("./ATT/ATTAleckSans/att_aleck_sans_bd.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}
